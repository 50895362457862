/** Character Animation **/


@keyframes growingFume {
	0% {
		transform: scale(.6);
	}
	100% {
		transform: scale(1);
	}
}

@keyframes floatingAround {
	0% {
		transform: translate(0px,0px);
	}

	50% {
		transform: translate(0px,-10px);
	}

	100% {
		transform: translate(0px,0px);
	}
}

@keyframes wobble {
	0% {
		transform: rotate(0);
	}
	1.5% {
		transform: rotate(2deg);
	}
	3% {
		transform: rotate(-2deg);
	}
	4.5% {
		transform: rotate(2deg);
	}
	5.5% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(0);
	}
}


@media (prefers-reduced-motion: no-preference) {
	.svgfloating {
		animation: floatingAround 3s infinite linear;
	}
	.svgfloating:nth-of-type(1) {
		animation-delay: 0s;
	}
	.svgfloating:nth-of-type(2) {
		animation-delay: -1s;
	}
	.svgfloating:nth-of-type(3) {
		animation-delay: -2s;
	}

	.svgillu-mug {
		transform-origin: 520.05px 284.95px;
		animation: wobble 5s -1s infinite linear;
	}
	.svgillu-fume {
		animation: growingFume 3s infinite alternate ease-in;
		transform-origin: 522.25px 187.55px;
	}

	.svgillu-mugandfume {
		transform-origin: 520.05px 256.2px;
	}
	.svgillu-sushi {
		transform-origin: 436.55px 90.3px;
		animation: wobble 5s 0s infinite linear;
	}
	.svgillu-fox {
		transform-origin: 106.85px 158.05px;
		animation: wobble 5s -2s infinite linear;
	}
	.svgillu-stef {
		transform-origin: 284.45px 446.4px;
	}

}





/** Behold the plant animation **/

// let's start with the stroke dash animation
$stem-duration: 4s;
$slow-timing: 700ms;
$med-timing: 500ms;
$fast-timing: 300ms;
$t-cubic-bounce: cubic-bezier(0.24, 0.95, 0.89, 1.18);
@mixin animateDash($duration, $direction: normal) {
	animation: dash $duration $direction forwards;
}
@mixin dashArray($dashvalue) {
	stroke-dasharray: $dashvalue;
	stroke-dashoffset: $dashvalue;
}
@media (prefers-reduced-motion: reduce) {
.js {
	.sparkles,
	.stars {
		opacity: 0;
		transition: opacity 3s ease-in;
	}

	.plantAndLeaves {
		opacity: 0;
		transition: opacity 3s ease-in;
	}

	.animate {
		.plantAndLeaves {
			opacity: 1;
			transition: opacity 3s ease-in;
		}

		.stars {
			opacity: 1;
			transition: opacity 3s ease-in;
		}
	}
}

}
@media (prefers-reduced-motion: no-preference) {
	.js {
		.stem {
			stroke-width: 2.44px;
			@include dashArray(500);
		}

		.branch-small {
			@include dashArray(26);
		}

		.branch-medium {
			@include dashArray(34);
		}

		.animate {
			.stem {
				@include animateDash($stem-duration);
				animation-timing-function: linear;
			}

			.branch-small {
				@include animateDash(0.8s);
				animation-timing-function: cubic-bezier(0, 0, 0.57, 1.02);
			}

			.branch-medium {
				@include animateDash(1s);
				animation-timing-function: cubic-bezier(0, 0, 0.57, 1.02);
			}

			.branch-01-right {
				animation-delay: $stem-duration/42;
			}

			.leaf-01-right {
				animation: leafbigger $med-timing forwards;
				animation-delay: $stem-duration/30;
			}

			.branch-01-left {
				animation-delay: $stem-duration/17;
			}

			.leaf-01-left {
				animation: leafbigger $med-timing forwards;
				animation-delay: $stem-duration/12;
			}

			.branch-02-right {
				animation-delay: $stem-duration/8;
			}

			.leaf-02-right {
				animation: leafbigger $slow-timing forwards;
				animation-delay: $stem-duration/6;
			}

			.branch-02-left {
				animation-delay: $stem-duration/5.1;
			}

			.leaf-02-left {
				animation: leafbigger $slow-timing forwards;
				animation-delay: $stem-duration/4.7;
			}

			.branch-03-right {
				animation-delay: $stem-duration/4;
			}

			.leaf-03-right {
				animation: leafbigger $med-timing forwards;
				animation-delay: $stem-duration/3.6;
			}

			.branch-04-right {
				animation-delay: $stem-duration/2.9;
			}

			.leaf-04-right {
				animation: leafbigger $med-timing forwards;
				animation-delay: $stem-duration/2.5;
			}

			.branch-03-left {
				animation-delay: $stem-duration/2.4;
			}

			.leaf-03-left {
				animation: leafbigger $slow-timing forwards;
				animation-delay: $stem-duration/2.3;
			}

			.branch-05-right {
				animation-delay: $stem-duration/2;
			}

			.leaf-05-right {
				animation: leafbigger $slow-timing forwards;
				animation-delay: $stem-duration/2;
			}

			.branch-04-left {
				animation-delay: $stem-duration/1.72;
			}

			.leaf-04-left {
				animation: leafbigger $med-timing forwards;
				animation-delay: $stem-duration/1.72;
			}

			.branch-06-right {
				animation-delay: $stem-duration/1.42;
			}

			.leaf-06-right {
				animation: leafbigger $med-timing forwards;
				animation-delay: $stem-duration/1.4;
			}

			.branch-05-left {
				animation-delay: $stem-duration/1.15;
			}

			.leaf-05-left {
				animation: leafbigger $med-timing forwards;
				animation-delay: $stem-duration/1.15;
			}

			.leaf-final-mid {
				animation: leafbigger $fast-timing forwards;
				animation-delay: $stem-duration/1.06;
			}

			.leaf {
				animation-timing-function: $t-cubic-bounce;
			}
			// success animation at the end
			.stars {
				animation: opacityAppear $fast-timing forwards;
				animation-delay: $stem-duration;
				animation-timing-function: $t-cubic-bounce;
			}

			.star-01 {
				animation: pulse 3s infinite;
				animation-delay: $stem-duration + 0.7s;
			}

			.star-02 {
				animation: pulse 3s infinite;
				animation-delay: $stem-duration + 1.2s;
			}

			.star-03 {
				animation: pulse 3s infinite;
				animation-delay: $stem-duration + 1.5s;
			}

			.sparkles .line {
				animation: dashSparkle 0.4s ease-in-out;
				animation-delay: $stem-duration;
			}
		}

		.stars {
			opacity: 0;
		}

		.leaf {
			opacity: 0.8;
		}

		.leaf-01-right {
			transform: translate(-7px, 7px) scale(0);
			transform-origin: 45.79px 420.83px;
		}

		.leaf-01-left {
			transform: translate(7px, 7px) scale(0);
			transform-origin: 29.75px 399.8px;
		}

		.leaf-02-right {
			transform: translate(-10px, 10px) scale(0);
			transform-origin: 53px 364.68px;
		}

		.leaf-02-left {
			transform: translate(7px, 7px) scale(0);
			transform-origin: 28.22px 341.98px;
		}

		.leaf-03-right {
			transform: translate(-7px, 7px) scale(0);
			transform-origin: 45.58px 304.49px;
		}

		.leaf-04-right {
			transform: translate(-3px, 3px) scale(0);
			transform-origin: 49.1px 250.92px;
		}

		.leaf-03-left {
			transform: translate(10px, 7px) scale(0);
			transform-origin: 30.13px 217.64px;
		}

		.leaf-05-right {
			transform: translate(-10px, 10px) scale(0);
			transform-origin: 48.51px 173.52px;
		}

		.leaf-04-left {
			transform: translate(7px, 7px) scale(0);
			transform-origin: 33.35px 139.12px;
		}

		.leaf-06-right {
			transform: translate(-3px, 3px) scale(0);
			transform-origin: 58.97px 78.99px;
		}

		.leaf-05-left {
			transform: translate(7px, 7px) scale(0);
			transform-origin: 33.58px 68.03px;
		}

		.leaf-final-mid {
			transform-origin: 39.17px 32.83px;
			transform: translate(0px, 5px) scale(0);
		}

		.star-01 {
			transform-origin: 54.26px 7.95px;
		}

		.star-02 {
			transform-origin: 12.9px 16.6px;
		}

		.star-03 {
			transform-origin: 63.06px 36.95px;
		}

		.sparkles .line {
			stroke-dasharray: 10;
			stroke-dashoffset: 10;
		}
		@keyframes dash {
			to {
				stroke-dashoffset: 0;
			}
		}
		@keyframes dashSparkle {
			to {
				stroke-dashoffset: -7;
			}
		}
		@keyframes opacityAppear {
			to {
				opacity: 1;
			}
		}
		@keyframes leafbigger {
			to {
				transform: translate(0px, 0px) scale(1);
				opacity: 1;
			}
		}
	}
}
