/* Fonts */
$ff-booster: 'Booster Next FY', Helvetica, Arial, sans-serif;
$ff-common: 'Libre Franklin', Helvetica, Arial, sans-serif;
// $ff-common-italic: 'Libre Franklin Italic', Helvetica, Arial, sans-serif;

$wght-normal: 'wght' 350;
$wght-fake-italic: 'wght' 300;
$wght-medium: 'wght' 400;
$wght-semibold: 'wght' 500;
$wght-bold: 'wght' 600;


/* Colors */
$primary-50: #FEDC2A; // yellow
$secondary-60: #5A3B5D; // dark secondary
$secondary-50: #8A538E; // prune
$secondary-30: #C7A9CD;


$error: #C73248;
$error-light: transparentize($error, .9);
$success: #016F5B;
$success-light: #EAFDF6;

/* Fifty shades of… */

// Darks
$gray-80: #333333;
$gray-70: #4D4D4D;
$gray-40: #666666;

//lights
// $gray-15: #D9D9D9;  removed and replaced by gray-10
$gray-10: #E6E6E6; //borders
$gray-02: #FAFAFA; // backgrounds of boxes
$gray-01: #fdfdfd; // (gray-01 on sketch)


/* Dimensions & Spacing */
$container-width: 1024px;
$overflow-width: 1104px;
$website-width: 1164px;
$entry-content-size: 700px;
$overflow-size: 150px;

/* Main breakpoints (component related) */
$xlarge-width: 1680px;
$medium-width: 914px;
$small-width: 850px;
$xsmall-width: 680px;
$xxsmall-width: 520px;

$overflow-neg-margin: -40px;
$global-lat-spacing: 16px;
$global-lat-spacing-small: 12px;



/* link underline mixin */

@mixin links-underline($color){
	background-image: linear-gradient(
		transparent 83%,
		$color 83%,
		$color 90%,
		transparent 90%,
		transparent 100%
	);
}

@mixin links-overline($color){
	background-image: linear-gradient(
		transparent 55%,
		$color 55%,
		$color 85%,
		transparent 85%,
		transparent 100%
	);
}

@mixin dots-light(){
	background-image:
		linear-gradient(to bottom, rgba(252,252,252,0), rgba(252, 252, 252, 1) 514px),
		radial-gradient($gray-10 10%, transparent 10%),
		radial-gradient($gray-10 10%, transparent 10%);
	background-position: 0 0,0 4px,8px 12px;
	background-size: 100%, 16px 16px, 16px 16px;
}

@mixin lines-separatorTop($stripeHeight: .4em){
	&:before {
		content: '';
		position: absolute;
		top:-.4em;
		left: 0;
		right: 0;
		height: $stripeHeight;
		@include stripes();

			@media (min-width: $website-width) {
				left:-48px;
				right: -48px;
			}
	}
}

@mixin screenreader-hidden(){
	position: absolute !important;
	width: 1px !important;
	height: 1px !important;
	padding: 0 !important;
	border: 0 !important;
	clip: rect(1px, 1px, 1px, 1px) !important;
	-webkit-clip-path: inset(50%) !important;
		clip-path: inset(50%) !important;
	overflow: hidden !important;
	white-space: nowrap !important;
	z-index: 105;
}

/** used on the blog **/
@mixin dots-primary(){
	$localpurple : rgba(90, 59, 93, .2);
	background-image:
		linear-gradient(to bottom, $primary-50 160px, transparent 160px) // yellow bg;
}

@mixin dots-full-bg (){
	background-color: #fcfcfc;
	opacity: 1;
	background-image:
		radial-gradient($gray-10 0.8px, transparent 0.8px),
		radial-gradient($gray-10 0.8px, #fcfcfc 0.8px);
	background-size: 32px 32px;
	background-position: 0 0,16px 16px;
}

@mixin stripes(){
	background: repeating-linear-gradient(
		-45deg,
		rgba(255,255,255,0) 0px,
		rgba(255,255,255,0) 6px,
		$gray-10 6px,
		$gray-10 8px
		);
}

@mixin image-border(){
	box-shadow:
		0 0 0 1px $gray-10;
}

@mixin accessibilityfocus ($offSet: 0.12em, $color: $secondary-50){
	&:focus:not(:focus-visible) {
		outline:0;
	}
	&:focus-visible {
		outline: 2px solid $color;
		outline-offset: $offSet;
	}
}
