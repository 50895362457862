@font-face {
	font-family: 'Libre Franklin';
	src: url('../fonts/LibreFranklin-sub.woff2') format('woff2');
		// url('../fonts/LibreFranklin.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
	font-display: swap;
	font-feature-settings: "kern" 1;

}

// @font-face {
// 	font-family: 'Libre Franklin Italic';
// 	src: url('../fonts/LibreFranklin-Italic[wght].ttf') format('truetype');
// 	font-weight: 200;
// 	font-style: italic;
// 	font-display: swap;

// 	font-feature-settings: "kern" 1;

// }


@font-face {
	font-family: 'Booster Next FY';
	src: url('../fonts/BoosterNextFY-Bold-sub.woff2') format('woff2'),
		 url('../fonts/BoosterNextFY-Bold.woff') format('woff'),
		 url('../fonts/BoosterNextFY-Bold.otf') format('opentype');
	font-style: normal;
	font-weight: 700;
	font-display: swap;
}

// @font-face {
// 	font-family: 'Booster Next FY';
// 	src: url('../fonts/BoosterNextFY-Medium.woff2') format('woff2'),
// 		 url('../fonts/BoosterNextFY-Medium.woff') format('woff'),
// 		 url('../fonts/BoosterNextFY-Medium.otf') format('opentype');
// 	font-style: normal;
// 	font-weight: 500;
// }

// @font-face {
// 	font-family: 'Booster Next FY';
// 	src: url('../fonts/BoosterNextFY-Regular.woff2') format('woff2'),
// 		 url('../fonts/BoosterNextFY-Regular.woff') format('woff'),
// 		 url('../fonts/BoosterNextFY-Regular.otf') format('opentype');
// 	font-style: normal;
// 	font-weight: 400;
// }


// @font-face {
//     font-family: 'ovoregular';
//     src: url('ovo-regular-webfont.eot');
//     src: url('../fonts/ovo-regular-webfont.eot?#iefix') format('embedded-opentype'),
//          url('../fonts/ovo-regular-webfont.woff2') format('woff2'),
//          url('../fonts/ovo-regular-webfont.woff') format('woff'),
//          url('../fonts/ovo-regular-webfont.ttf') format('truetype'),
//          url('../fonts/ovo-regular-webfont.svg#ovoregular') format('svg');
//     font-weight: normal;
//     font-style: normal;

// }
