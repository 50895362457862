@import 'variables';
@import 'fonts';
@import 'homepageanimations';

* {
	box-sizing: border-box;
}

.dots {
	@include dots-full-bg();
}

.primary-dots {
	@include dots-primary();
}

html, body, h1, h2, h3, h4, h5, h6, p, ol, ul {
	margin: 0;
	padding: 0;
	list-style: none;
	// font-weight: normal;
}


html {
	font-size: 100%;
	font-variation-settings: "wght" 100;
	-webkit-text-size-adjust: none;

	&.scroll-locked {
		overflow: hidden;
		position: fixed;
	}
}

body {
	font-family: $ff-common;
	font-variation-settings: $wght-normal;
	font-size: 100%;
	line-height: 1.5;
	scroll-behavior: smooth;
	color: $gray-70;
	overflow-x:hidden; // fixing some full bleed scrolling issues 

	.nav-is-open & {
		// overflow: hidden; // @debug removed to try to fix a FF mobile bug with the overflow of the menu --> this overflow is the one that is making FF mobile bug when the user scrolls top again
	}

	&.he-can-fly {
		// overflow-x: hidden;
	}
}
strong {
	font-variation-settings: $wght-bold;
	font-weight: normal;
}

em {
	font-variation-settings: $wght-fake-italic;
}

img {
	max-width: 100%;
	height: auto;
}

picture {
	margin: 0;
	padding: 0;
}

::selection {
	background: $secondary-50;
	color: #fff;
}
::-moz-selection {
	background: $secondary-50;
	color: #fff;
}

button {
	cursor: pointer;

	&:focus {
		outline: none;
	}
}



/* Default links */


a {
	text-decoration: none;
}

a {
	text-decoration: underline;
	color: $gray-70;
	transition: all .2s;

	&:hover,
	&:active,
	&:focus {
		text-decoration: none;
		color:$secondary-50;
		transition: all .2s;
	}

	@include accessibilityfocus();
	
	
}

h1, h2, h3, h4, h5, h6 {

	a {
		text-decoration: none;
	}
}


/* Styling the main links */

.entry-content,
.special-content,
.entry-content h2,
.entry-content h3,
.entry-content h4,
.worktogether,
.sidebar,
.author-block,
.description {
	a:not(.button){
			text-decoration: none;
			padding:.2em 0;
			background-repeat: no-repeat;
			background-size: 100% 100%;
			transition: all .1s;

		 	&:link{
				color: $secondary-60;
				@include links-underline($secondary-50);
				background-color: $gray-02;
			}

			&:visited {
				color: $secondary-60;
			}

			&:hover,
			&:active,
			&:focus {
				background-size: 0% 100%;
				transition: all .3s;
			}
		}

		a.link-on-image{
			background: none;

			img{
				transition: transform .3s;
			}
			&:link{
				background: none;
			}

			&:hover,
			&:focus {
				background: none;

				img{
					transform: scale(1.03);
					transition: transform .3s;
				}
			}

		}
}

/* specific visited links for the post format link */ 

.post_format-post-format-link .entry-content a:not(.button):visited {
	color: $gray-40;
	background-color: #F2F2F2;
}


/* style purple alternate ones */

.main-text-content{
	.entry-content.is-alternate,
	.work-item:nth-child(2n) .work-desc{
		a:not(.button){
			// @include links-underline($secondary-30);
		}
	}
}



/* Typography */
// defining paragraph typography
div,
ol,
p,
ul {
	font-size: 1.1rem;
	line-height: 1.6;
	@media (max-width: $small-width - 1px) {
		font-size: 1rem;
	}
}
h1, h2, h3, h4, h5, h6, .h1-like, .h2-like, .h3-like, .h4-like, .h5-like, .h6-like {
	font-family: $ff-booster;
}
h1,
.h1-like {
	font-size: 2rem;
	font-weight: 700;
	line-height: 1.1;

	@media (min-width: $xsmall-width){
		font-size: 2.3rem;
	}

	@media (min-width: $small-width) {
		font-size: 2.625rem;
	}
	
}

h2,
.h2-like {
	//margin-bottom: 1em;
	font-size: 1.6rem;
	line-height: 1.1;
	font-weight: 700;
	text-align: center;

	@media (min-width: $xsmall-width){
		font-size: 1.8rem;
	}

	@media (min-width: $small-width) {
		font-size: 2.25rem;
		line-height: 1.2;
	}
}

.container h2 {
	margin-bottom: 1em;
}

h3,
.h3-like {
	font-size: 1.4rem;
	line-height: 1.1;
	font-weight: 700;
	letter-spacing: -0.01em;

	@media (min-width: $xsmall-width){
		font-size: 1.6rem;
	}

	@media (min-width: $small-width) {
		font-size: 1.75rem;
	}
}

h4,
.h4-like {
	font-size: 1.25rem;
	font-weight: 700;
	line-height: 1.1;
	letter-spacing: -0.01em;

	@media (min-width: $xsmall-width){
		font-size: 1.35rem;
	}

	@media (min-width: $small-width) {
		font-size: 1.5rem;
		line-height: 1.2;
	}
}


h5,
.h5-like {
	font-size: 1.15rem;
	font-weight: 700;
	letter-spacing: -0.01em;
	line-height: 1.2;

	@media (min-width: $small-width) {
		font-size: 1.3rem;
	}
}

.subtitle {
	width: 820px;
	max-width: 100%;
	text-align: center;
	margin: 2em auto 2em;
	p {
		margin-bottom: .8em;
	}
}

.entry-content {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: $secondary-60;
		text-align: left;
	}

	h1,
	.h1-like {
		margin-top: 2em;
		scroll-margin-top: 4em;
	}

	h2,
	.h2-like {
		margin-top: 2em;
		scroll-margin-top: 4em;
	}

	h3,
	.h3-like {
		margin-top: 2.4em;
		scroll-margin-top: 4em;
	}

	h4,
	.h4-like {
		margin-top: 2em;
		scroll-margin-top: 4em;
	}

	h5,
	.h5-like {
		margin-top: 1.8em;
	}

	p, ul, ol {
		margin: 1.4em 0 ;
	}

	> p {
		&:first-of-type {
			margin-top: 0;
		}
	}
}



// coloring the Hns in purple dark
.introduction p,
.h1-like,
.h2-like,
.h3-like {
	color: $secondary-60;
}


.sub-title {
	font-size: 1.2em;
	margin-bottom: 1.2em;
	color: $secondary-60;
	font-variation-settings: $wght-medium;
}

.blog .sub-title {
	text-align: center;
}

/**
 *  list items.
 *  list content.
 */

.entry-content ul li,
.single .entry-content ul li,
li.pingback,
.sidebar ul li {
	position: relative;
	padding: 0 0 0 1.5em;
	margin: 1.2em 0;
	list-style: none;
	line-height: 1.5;

	&:before {
		content: '';
		position: absolute;
		top: .6em;
		left: 0;
		width: 12px;
		height: 4px;
		background: $secondary-30;
		transform: skew(5deg);

	}
}

/** remove them for the mailchimp form */
.entry-content .mailchimp ul {
	margin-bottom: .5em;
}
.entry-content .mailchimp ul li {
	padding: 0 0 0 1em;
	margin: 0;
	&:before {
		width: 0;
		height: 0;
	}
	&:last-child() label{
		margin-bottom: 0;
	}
}


/* OL lists */

$CounterSize: 30px;

.single .entry-content {
	ol {
		list-style: none;
		counter-reset: stef-nice-counter;
		padding-left: $CounterSize;
	}

	ol>li {
		counter-increment: stef-nice-counter;
		margin: 0 0 1.2rem 0;
		position: relative;
	}

	ol>li::before {
		content: counter(stef-nice-counter) ".";
		position: absolute;
		top: 0;
		left: calc(-1 * #{$CounterSize});
		width: $CounterSize;
		height: $CounterSize;
		font-family: ovoregular;
		font-size: 1.3rem;
		line-height: $CounterSize;

	}

	ol>li::after {
		content: '';
		position: absolute;
		//z-index: -1;
		width: .8em;
		height: .3em;
		background: $secondary-30;
		transform: skew(-8deg) translateX(-50%);
		top: calc(#{$CounterSize} - .5em);
		left: calc(-1 * #{$CounterSize} + .4em);
	}
}


/**
 * Common classes
 */
.screen-reader-text {
	@include screenreader-hidden();

	&.is-focusable:focus,
	&.is-focusable:active {
		width: auto !important;
		height: auto !important;
		clip: auto !important;
		-webkit-clip-path: none !important;
			clip-path: none !important;
		overflow: visible !important;
		white-space: normal !important;
		padding: 10px!important;
		background: $primary-50;
		color: $secondary-60;
		font-weight: bold;
		text-decoration: none;
	}
}

/* Skip Link */
a.skip-links {
	&.is-focusable:focus,
	&.is-focusable:active {
		background: $secondary-60;
		color: white;
	}
}

.no-js .hide-if-no-js{
	display: none;
}




/**
 * Utilities
 */
.upper.upper {
	text-transform: uppercase;
	font-size: .9rem;
	font-weight: 500;
	@media (min-width: $xsmall-width) {
		font-size: 1.0625rem;
	}
}

.small.small {
	font-size: 0.875rem;
}

.bold.bold {
	font-weight: 700;
}

.text-center.text-center {
	text-align: center;
}
.text-left.text-left {
	text-align: left;
}



.entry-content *.full-bleed,
.entry-content *.align-wrap-full{
  width: 100vw;
  margin-left: calc(50% - 50vw);
}
.entry-content p.full-bleed {
	padding:32px;
}

.entry-content *.full-bleed .wp-caption {
	max-width: 100vw;
	img {
		display: block;
		margin: 0 auto;
	}
}

.full-bleed-center {
	max-width: $entry-content-size;
	margin: 0 auto;
}



/**
 * Overlined effect.
 */

.is-overlined {
	position: relative;
	display: inline-block;
	z-index: 1;
}

.is-overlined:before,
.main-nav a:before {
	content: '';
	position: absolute;
	top: auto;
	bottom: .25em;
	height: .4em;
	left:0;
	right:0;
	background: $primary-50;
	transform: scale(1) skew(-8deg) ;
	z-index: -1;
}

.is-overlined.is-alternate:before {
	background: $secondary-30;
}

.main-footer .is-overlined:before {
	background: #FFF;
}
.h1-like,
.h2-like,
.h3-like,
.h4-like{
	&.is-overlined {
		display: block;
	}
}


.h1-like.is-overlined,
.h2-like.is-overlined {
		&:before {
			width: clamp(30px, 20vw, 150px);
			bottom: .1em;

			@media (max-width: $small-width - 1px) {
				width: 100px;
			}
		}
}

.h3-like.is-overlined,
.h4-like.is-overlined {
		&:before {
			width: 65px;
			bottom: .1em;
		}
}

// center the underline on overlined centered
.h1-like.is-overlined,
.h2-like.is-overlined,
.h3-like.is-overlined,
.h4-like.is-overlined {
	.text-center &,
	&.text-center {
		&:before {
			transform: skew(-8deg) translateX(-50%);
			left: 50%;
			right: auto;
		}
	}
}

.section-skills .h3-like.is-overlined:before {
	@media (max-width: $xsmall-width - 1px) {
		transform: skew(-8deg) translateX(-50%);
		left: 50%;
		right: auto;
	}
	
	@media (min-width: $xlarge-width + 1px) {
		transform: skew(-8deg) translateX(-50%);
		left: 50%;
		right: auto;
	}
}


.text-left.is-overlined {
	&:before {
		width: 65px;
		// height: 10px;
	}
}


/**
 * Global Layout
 */
.container {
	width: $container-width; // 1024px
	max-width: 100%;
	margin: 0 auto;
}

.container {
	p,
	.h2-like,
	.h3-like {
		&:first-child {
			margin-top: 0;
		}
	}
}

@media (min-width: $website-width) {
	.overflowing {
		width: $overflow-width; // ~1104px

		.container & {
			margin-left: $overflow-neg-margin;
		}
	}
}

[class^="section-"] {
	padding-left: $global-lat-spacing;
	padding-right: $global-lat-spacing;
	
}

section {
	scroll-margin-top: 6em;
}


/* the introduction of speaking and training */ 
.section-primary {
	margin-top: 3em;
}

.section-primary.intro{
	padding: 0em $global-lat-spacing;
	
	//color: $secondary-60;

	a {
		border-color: #fff;
		display: inline-block;

		&:hover,
		&focus {
			background: #fff;
		}
	}

	.container {
		// max-width: $container-width;
		width: 800px;

		p {
			// line-height: 1.2;
		}
	}

	.h1-like,
	.h2-like {
		&:first-child {
			margin-top: 0;
		}
	}

	.is-overlined:before {
		// width: 114px;
		// height: 14px;
		// bottom: 0;
		// background: $primary-50;
	}

	
}
.section-cta {
	max-width: $entry-content-size;
	margin: 0 auto 0em auto;
}

.section-workshops .section-cta {
	margin: 0 auto 2em auto;
}

.training-type {
	span {
		//text-transform: uppercase;
		margin-bottom: 0.5em;
		//letter-spacing: -0.03em;
		font-size: 0.8em;
		background-color:$secondary-60;
		color: #fff;
		display: inline-block;
		padding: 0.2em 0.5em;
	}
	
	@media (max-width:$small-width){
		text-align: center;
	}

}

.section-writer {
	margin-top: 24px;
}

.divider {
	position: relative;
	height: 12px;
	margin: 3.5em auto;
	@include stripes();
	width: calc(#{$container-width} + 48px);

	@media (max-width:$small-width){
		margin: 2em auto;
	}

	@media (max-width:$xsmall-width){
		margin: 1em auto 1.5em;
	}

	@media (max-width: $website-width) {
		width: auto;
		margin-left: 16px;
		margin-right: 16px;
	}

}



.section-gray {
	margin-top: 65px;
	padding-top: 50px;
	background: $gray-02;
	border-top: 1px solid $gray-10;
}

.section-light {
	// margin-top: 65px;
	padding-top: 50px;
	padding-bottom: 50px;
	background: white;
	border-top: 1px solid $gray-10;
}

.section-dark {
	padding-top: 50px;
	padding-bottom: 50px;
	background: $secondary-60;
	color: #fff;
}




/**
 * Forms
 */
.button,
textarea,
select,
input {
	font-family: $ff-common;
	font-variation-settings: $wght-medium;
	text-decoration: none;
}
.button{
	transition: all .15s;
	text-decoration: none;
}

textarea,
select,
input {
	width: 100%;
	padding: 12px 10px;
	border: 1px solid $gray-40;
	font-size: 1rem;
	color: $gray-80;
	background: #FFF;
	border-radius: 3px;
	@include accessibilityfocus(0.12em,$secondary-50)

	// &:focus {
	// 	outline: 0.06em solid red;
	// 	outline-offset: 1px;
	// 	color: $secondary-50;
	// 	// box-shadow: 6px 6px 0 $secondary-30;
	// }
}

input[type="radio"],
input[type="checkbox"] {
	width: auto;
	margin-right: 16px;
}


label,
legend {
	font-size: 1em;
	display: inline-block;
	font-weight: 700;
	font-family: $ff-booster;
}

label {
	margin-bottom: 0.3em;
}

label em {
	font-weight: normal;
}

.entry-content fieldset {
	border: 1px solid $gray-40;
	border-radius: 2px;
	padding: 1em;

	ul {
		margin-top: 0;
	}
	ul li {
		padding-left: 1em;
	}
}
/* styling the checkboxes */
/* Removing the checkbox from screen */
input[type="checkbox"] {
	position: absolute;
	left: -300%;
	transform: scale(0);
}

input[type="checkbox"] + label {
	position: relative;
	margin-bottom: 1em;
	cursor: pointer;
	display: grid;
	grid-template-columns: 1.2em auto;
	grid-template-rows: 1;
	grid-column-gap: 1.5em;
}
/* Creating the fake checkbox */
input[type="checkbox"] + label:before {
	content: '';
	display: block;
	width: 1.2em;
	height: 1.2em;
	border-radius: 2px;
	border: 1px solid $secondary-60;
	grid-column: 1 / 2;
	grid-row: 1 / 2;
	justify-self: center;
	align-self: center;
}
/* Adding the SVG mark */
input[type="checkbox"] + label:after {
	content: " ";
	background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.7 10.7"><path fill="white" d="m4.2 9.7c-.3 0-.5-.3-.7-.5l-3.2-3.1c-.4-.4-.4-1.1 0-1.5s1-.4 1.4 0l2.4 2.3 4.9-5.6c.4-.4 1-.5 1.4-.1s.5 1 .1 1.4l-5.6 6.6c-.1.2-.4.5-.7.5z"/></svg>') no-repeat;
	/* OMG you can embed SVG in background, awesoome */
	display: block;
	position: relative;
	width: 1em;
	height: 1em;
	grid-column: 1 / 2;
	grid-row: 1 / 2;
	justify-self: center;
	align-self: center;
}
/* accessibility */
input[type="checkbox"]:focus + label:before {
	border-color: $secondary-60;
	color: $secondary-50;
	box-shadow: 4px 4px 0 $secondary-30;
	// outline: 0;
}
/* "normal" state */
/* transitionned state when checked */
input[type="checkbox"]:checked + label:before {
	background: $secondary-50;
	border-color: $secondary-50;
}
/* applying transition */
input[type="checkbox"] + label:before {
	transition: background 0.2s, border-color 0.2s, box-shadow .1s;
	/* double transition, yup ! */
}
/* Let's animate the mark */
input[type="checkbox"]:not(:checked) + label:after {
	transform: scale(0);
}

input[type="checkbox"]:checked + label:after {
	transform: scale(1);
}

input[type="checkbox"] + label:after {
	transition: transform 0.4s;
}

form [class$="-line"] + [class$="-line"] {
	margin-top: 1em;
}

.label-desc {
	display: block;
	margin-top: -4px;
	font-size: .875rem;
	color: $gray-70;
	font-weight: 400;
}

.select-line {
	select {
		appearance: textfield;
		-moz-appearance: textfield;
		background: url('../img/arrow-small-bottom.svg') right 16px top 50% no-repeat;
	}
}

.marvin {
	@extend .screen-reader-text;
}

/**
 * Flex Grid
 */

.flex {
	display: flex;
	// @media (min-width: $xsmall-width) {
	// 	display: flex;
	// 	// margin: 0 -16px;
	// }
}

.cols {
	@media (min-width: $xsmall-width) {
		display: flex;
		//margin: 0 -16px;
	}

	@media (min-width: $website-width) {
		margin: 0 -30px;
	}
}

@media (min-width: $xsmall-width) {
	.flex-col {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}
}

[class^="col-"] {
	padding: 24px 0;

	@media (min-width: $xsmall-width) {
		padding: 16px;
	}

	@media (min-width: $website-width) {
		padding: 30px;
	}
}

@media (min-width: $xsmall-width) {
	.col-2-3 {
		width: 66.66667%;
	}

	.col-1-2 {
		width: 50%;
	}

	.col-1-3 {
		width: 33.33333%;
	}
}

.align-center {
	justify-content: center;
}

.space-around {
	justify-content: space-around;
}

/**
 * Header + Navigation
 */
.logo,
.logo a{
	display: block;
	width: 184px;
	height: 31px;
	line-height: 1;

	path,
	circle {
		// fill: $secondary-60;
	}
	.primary-logo {
		fill: $secondary-60;
		transition: .3s fill linear;
	}

	.dot-circle{
		fill: #fff;
		transition: .3s fill linear;
	}

	&:hover {
		.primary-logo {
			fill: $secondary-50;
		}
		.dot-circle{
			fill: $secondary-50;
		}
	}

	a {
		border: 0;
		text-decoration: none;

		&:focus,
		&:hover {
			background: none;
		}
	}
}

.main-header {
	background: $primary-50;
	padding: $global-lat-spacing-small;
	z-index: 50;
	// box-shadow: 0 12px 12px -12px rgba(0,0,0,.1);


	/* sticky menu, always */
	position: sticky;
	top: 0;
	left:0;
	right:0;
//	animation: slideDown .475s 1 forwards;
	// overflow: hidden; // @debug: quick fix to solve the navigation problem on firefox



	.single & {
		box-shadow:none;
	}

	@media (min-width: $medium-width) {
		padding: 18px;
	}

	.container {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	// .he-can-fly & {
	// 	position: sticky;
	// 	top: 0;
	// 	left:0;
	// 	right:0;
	// 	animation: slideDown .475s 1 forwards;
	// 	overflow: hidden; // @debug: quick fix to solve the navigation problem on firefox
	// }
}

@keyframes slideDown {
	0% {
		transform: translateY(-100%);
	}
	100% {
		transform: translateY(0);
	}
}

// We need to add .menu-item-blog to the blog link in the WP menu

.blog .main-nav {
	li.menu-item-blog a:before {
		right: 10%;
		opacity: 1;
		background: $secondary-50;
		}
}

.langFR {
	.main-nav ul {
		@media (min-width: $medium-width) {
			gap: 1.5em;
			margin-left: 20px;
		}
	}
}

.main-nav {
	font-family: $ff-booster;
	ul {
		// text-align: right;
		// margin-right: -40px;
		@media (min-width: $medium-width) {
			display: flex;
			gap: 2em;
			justify-content: flex-end;
			align-items: center;

			li {
				text-align: center;
				line-height: 1em;
			}
			
		}

	}

	// Navigation links underlines
	a {
		position: relative;

		display: block;
		// margin: 0 24px;
		font-weight: 700;
		// display: inline-block;
		// padding: 1em 0;
		color: $secondary-60;
		text-decoration: none;
		z-index: 0;

		&:hover {
			transform: translateY(.08em);
			transition: all .275s;
		}


		&:before {
			height: .25em;
			left: 10%;
			bottom: -.4em;
			right: 90%;
			opacity: 0;
			transition: all .275s;
		}

		&.current-menu-item:before,
		&:hover:before,
		&:focus:before {
			right: 10%;
			opacity: 1;
		}

		&:hover:before,
		&:focus:before {
			background: $secondary-50;
		}
	}

	li {
		
		// display: inline-block;

	// display the underline on currently selected item
		&.current-menu-item {
			a:before {
				background: $secondary-50;
				right: 10%;
				opacity: 1;
				transition: all .175s;
			}
			
			a:hover:before,
			a:focus:before {
				background: $secondary-30;
			}
		}
	}

	// style the contact item with a different style
	@media (min-width: $medium-width) {
		.menu-item-contact a {
			border: 2px solid $secondary-60;
			padding: 0.4em 0.7em;
			border-radius: 3px;
			z-index: 1;

			&::before {
				opacity: 0; // the before is declared on other elements but hidden
			}

			&:hover, 
			&:focus{
				color: white;
				background: $secondary-60;
				transform: none;
			}
			&:focus {
				outline-offset: .1em;
			}
		}
	}



	@media (max-width: $medium-width - 1px) {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		text-align: center;
		background: #FFF;
		opacity: 0;
		z-index: -1;
		visibility: hidden;
		transition: all .375s;
		bottom: auto;
		height: 100vh;

		// .he-can-fly & {
		// 	bottom: auto;
		// 	height: 100vh;
		// }

		&:before {
			content: '';
			position: absolute;
			top: 0; right: 0;
			left: 0; bottom: 0;
			background: $primary-50;
			transform: skew(-14deg) translateX(-120%);
			transform-origin: 0 0;
			left: 0;
			right: -24px;
			transition: all .275s .1s;
		}

		ul {
			display: inline-flex;
			flex-direction: column;
			height: 100%;
			align-items: flex-end;
			justify-content: center;
			transform: translateX(-18%) skew(-16deg);
		}

		li {
			display: block;
			margin: .5rem;
			text-align: right;
			transform: skew(16deg);

			@for $i from 1 through 5 {
				&:nth-child(#{$i}) a {
					transition: all .275s .125s + $i * .05;
				}
			}
		}

		a {
			z-index: 1;
			font-size: 1.5rem;
			opacity: 0;
			transform: translateY(-10px);

			&:before {
				background: #FFF;
			}
		}

		&.is-open {
			opacity: 1;
			z-index: 100;
			visibility: visible;

			&:before {
				transform: skew(-14deg) translateX(0);
			}

			a {
				opacity: 1;
				transform: translateY(0);
			}
		}
	}
}



.open-main-nav,
.nav-search {
	display: none;
	visibility: hidden;
}


@media (max-width: $medium-width - 1px) {
	.open-main-nav.open-main-nav {
		display: block;
		visibility: visible;
		position: relative;
		z-index: 101;
		cursor: pointer;
		padding-top: 20px;
		margin-top: -2px;
		order: -1;

		span {
			display: block;
		}

		&:focus {
			// outline: 0;
		}
	}

	.close-icon.close-icon {
		display: block;
		transform: skew(5deg) translateY(-8px) rotate(-45deg);

		&::before {
			transform: translateY(0px) skew(-10deg) rotate(75deg);
		}
		&::after {
			transform: translateY(-12px) translateX(10px) skew(-20deg);
			opacity: 0;
		}
	}

	.burger,
	.close-icon {
		position: relative;
		width: 28px;
		height: 4px;
		margin: 0 auto;
		background: $secondary-60;
		transform: skew(5deg);
		transition: all .275s;

		.is-open + .open-main-nav & {
			transform: skew(5deg) translateY(-8px) rotate(-45deg);
		}

		&:before,
		&:after {
			content: '';
			display: block;
			height: 100%;
			background: $secondary-60;
			transition: all .275s;
		}

		&:before {
			transform: translateY(-16px) skew(-10deg);

			.is-open + .open-main-nav & {
				transform: translateY(0px) skew(-10deg) rotate(75deg);
			}
		}

		&:after {
			transform: translateY(-12px) translateX(-2px) skew(-20deg);

			.is-open + .open-main-nav & {
				transform: translateY(-12px) translateX(10px) skew(-20deg);
				opacity: 0;
			}
		}
	}

	.burger-text {
		margin-top: .5em;
		text-transform: uppercase;
		font-weight: 500;
		color: $secondary-60;
		text-align: center;
	}

	/**
	 * Search form
	 */
	.nav-search {
		display: block;
		visibility: visible;
		min-width: 32px;
	}

	.nav-search-button {
		position: relative;
		cursor: pointer;

		&::before {
			content: '';
			position: absolute;
			top: -24px;
			bottom: -24px;
			left: -16px;
			right: -16px;
		}

		svg {
			width: 22px;
			height: 22px;
		}
	}

	.nav-search-form {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		min-height: 100vh;
		display: flex;
		padding: 16px;
		background: white;
		z-index: -1;
		opacity: 0;
		visibility: hidden;
		justify-content: center;
		align-items: center;
		transition: opacity .375s, z-index .375s .475s;

		form {
			width: calc(100% + 32px);
			margin: -1.25rem -16px 0 -16px;
			padding: 56px 16px;
			background: $primary-50;
			transform: skew(-3deg) rotate(-3deg);

			> label,
			> p {
				transform: skew(3deg) rotate(3deg);
			}

			> label {
				margin-top: -10px;
				margin-bottom: 24px;
				color: $secondary-60;
			}
		}

		&.is-open {
			z-index: 500;
			opacity: 1;
			visibility: visible;
			transition: opacity .375s;

			.close-button {
				transform: scale(1);
			}
		}

		.close-button {
			position: absolute;
			top: 20px;
			right: 22px;
			height: 45px;
			transform: scale(0);
			transition: all .275s;
		}
	}
}

.search-form {
	
	label {
		margin-bottom: 0;
	}

	.form-group  {
		display: grid;
		padding:  .4em;
		grid-template-columns: auto clamp( 40px, 2.5em, 80px);
		grid-gap: 0.3em;
	}

	input {
		height: 2.5em;

	}

	.search-icon.button{
		padding: .3em .3em 0  .3em ;
		min-width:2em;
		margin: 0;
		scale: 0.92;

		svg {
			width: 1.2em;
			height: 1.2em;
		}
		
	}
}

/**
 * "Main" overflow bug on home.
 */
[id="main"] {
	//overflow: hidden;
}

/**
 * Homepage Header
 */

 .grid {
	 display:grid;
 }


/* Section hellome homepage */
.section-hellome {
	padding-top: 24px;
	padding-bottom: 24px;

	@media (min-width: $xsmall-width) {
		padding: 52px 32px 0;
	}

	@media (min-width: $container-width) {
		padding: 52px 32px 0;
	}

	.grid {
		grid-template-columns: 3fr 4fr;
		gap: 24px 24px;

		@media (min-width: $xsmall-width) {
			grid-template-columns: 3fr 5fr;
			gap: 24px 40px;
		}

		@media (min-width: $container-width) {
			gap: 24px 60px;
		}
	}

	.the-kitten{
	// make the image smaller than for smaller screens
		align-self: center;
		justify-self: stretch;

		.stefportrait {
			@media (max-width: $xsmall-width - 1px) {
				display: block;
				max-width: 180px;
				margin: 0 auto;
				width: 100%;
			}
		}

	// let the image be full width for bigger screens

		@media (min-width: $xsmall-width) {
			justify-self: center;
			grid-row-start: span 2;
			width: 100%;
		}

	}

	.introduction {
		align-self: center;

		@media (min-width: $xsmall-width) {
			align-self: end;
		}

	}

	.description {
		grid-column-start: span 2;

		@media (min-width: $xsmall-width) {
			grid-row-start: 2;
			grid-column-start: 2;
		}
	}

	.small {
		margin-top: 12px;
	}

	.mobile-hidden {
		@media (max-width: $xsmall-width) {
			display: none;
		}
	}


	// typography

	.big {
		font-size: clamp(1.2em, 4vw, 1.4em);
		line-height: 1.3;
		font-family: $ff-common;
		font-variation-settings: $wght-normal;
		font-weight: normal;
	
		strong {
			font-family: $ff-booster;
			font-size: 1.1em;
		
		}

		@media (min-width: $xsmall-width) {
			font-size: clamp(1em, 3.1vw, 1.7em);
		}

		// the ampersand because I am a princess
		svg {
			width: 19px;
			height: .7em;
			display: inline-block;
			fill: $secondary-60;

			@media (min-width: $small-width) {
				height: .7em;
			}
		}

	}

}


/* Section I share (blog) homepage */

.section-blog {

		@media (min-width: $xsmall-width) {
			.container {
				padding-left: 2em;
				padding-right: 2em;
			}
		}
		@media (min-width: $small-width) {
			.container {
				padding-left: 3em;
				padding-right: 3em;
			}
		}

		@media (min-width: $container-width) {
			.container {
				padding-left: 4em;
				padding-right: 4em;
			}
		}
	.grid {
		grid-template-columns: auto 40px;
		grid-template-rows: 0fr auto auto;
		gap: 0px 12px;

		@media (min-width: $xxsmall-width) {
			grid-template-columns: auto 70px;
			gap: 10px 24px;
		}


		@media (min-width: $xsmall-width) {
			grid-template-columns: auto 90px;
			gap: 10px 32px;
		}

		@media (min-width: $container-width) {
			gap: 10px 60px;
		}
	}

	.is-title {
		align-self: start;
		grid-row: 1;
		grid-column: 1 / 2;
		z-index: 2;

		@media (max-width: $xxsmall-width - 1px) {
			&.h2-like {
				text-align: left;

				&::before {
					left: 0;
					transform:none;
					width: clamp(30px,20vw,150px);
				}
			}
		}


		@media (min-width: $xxsmall-width) {
			grid-column: 1 / span 2;
		}

		@media (min-width: $container-width) {
			align-self: start;
			grid-row: 1;
			grid-column: 1 / span 2;
			z-index: 2;
		}

	}
	.introduction {
		grid-column: 1 / 2;
		grid-row: 2 / 3;

		@media (max-width: $xxsmall-width - 1px) {
			align-self: center;
			justify-self: center;
		}

	}

	.blogposts {
		grid-column: 1 / 3;
		grid-row: 3 / 4;

		@media (min-width: $xsmall-width) {
			grid-column: 1 / 2;
		}

		.h4-like {
			margin-top: 0;
		}

		ul li {
			margin: .6em 0;
		}
	}

	.plant {
		grid-column: 2 / 3;
		grid-row: 1 / span 2;

		align-self: end;
		justify-self: stretch;

		@media (min-width: $xxsmall-width) {
			grid-row: 1 / span 3;
			align-self: end;
		}

		.growing-plant {

			// super small
			display: block;

			// medium
			@media (min-width: $xxsmall-width) {
				grid-row-start: span 2;
				max-width: clamp(30px, 14vw, 74px);
			}

			// large
			@media (min-width: $small-width) {
				max-width: clamp(30px, 11vw, 88px);
			}
		}


	}
}

.section-writer.boxed--gray{

	margin: 4em auto;
	border-radius:0;
	border-left: none;
	border-right:none;

	@media (max-width:$xsmall-width){
		margin: 20px auto 40px;
	}

	.flex-col.col-1-2:first-child {
		@media (max-width:$xsmall-width){
			border-bottom: 1px solid $gray-10;
		}
	}
}

/**
 * Line with cols and icons
 */
.iconed-line {
	@media (max-width: $xsmall-width) {
		// text-align: center;
	}

	.icon {
		padding-bottom: 28px;
	}

	.h3-like {
		margin-bottom: 28px;
	}
}

/**
 * Buttons & CTAs
 */
.cta {
	margin-top: auto;
}

$bgPositionOut: 400px 210px;
$bgPositionIn: 100% 100%;

/**
 * Overlined hover effect on Buttons and Work Illus.
 */

.work-illu a,
.button,
a.button {
	font-family: $ff-booster;
	box-sizing: border-box;
	color: $secondary-60;
	position: relative;
	display: inline-block;
	background-image: linear-gradient(to right, $primary-50, $primary-50);
	background-position: $bgPositionOut;
	background-repeat: no-repeat;
	border: 0 none;
	z-index: 1;
	
	span:before {
		display: inline-block;
		content: '';
		position: absolute;
		top: -2px; right: -2px;
		bottom: -2px; left: -2px;
		box-shadow: inset 0 0 0 2px $secondary-60;
		border-radius: 3px;
	}

	&:before,
	&:after {
		content: '';
		position: absolute;
		bottom: -6px;
		right: -6px;
		background: $primary-50;
		z-index: -1;
		border-radius: 2px;
	}
	&:before {
		left: 5px;
		height: 8px;
	}
	&:after {
		top: 5px;
		width: 8px;
	}

	/**
	 * Variants
	 */
	 
	&.is-alternate,
	.work-item:nth-child(even) & {
		background-image: linear-gradient(to right, $secondary-30, $secondary-30);

		&:before,
		&:after {
			background: $secondary-30;
		}
	}
	&.is-ondark {
		background: #fff;

		&:hover {
			background: $primary-50;
		}
	}

}


.button {
	min-width: 150px;
	max-width: 100%;
	margin: .75em .33em;
	padding: .4em 1.3em;
	font-size: 1em;
	font-weight: bold;
	letter-spacing: -0.005em;
	color: $secondary-60;
	background-color: transparent;
	background-size: calc(100% - 5px) calc(100% - 5px);
	cursor: pointer;

	
	&.has-icon {
		padding-right: 45px;

		.icon {
			right: 10px;
		}
	}

	&.has-icon-left {
		padding-left: 45px;

		.icon {
			left: 10px;
		}
	}

	.icon {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);

		&.icon-arrow-right {
			transform: translateY(-50%) rotate(180deg) scale(.8);
			vertical-align: middle;
		}
	}

	&:active {
		transform: translateY(.08em);
	}

	&:hover,
	&.is-accentuated {
		background-color: transparent;
		background-position: $bgPositionIn;
	}
	&:focus-visible {
		outline: 1px solid auto;
		outline-offset: 0.2em;
	}

	@include accessibilityfocus(0.2em);

	&.is-accentuated {
		&:hover,
		&:focus {
			background-position: $bgPositionOut;
		}
	}

	span {
		> * {
			display: inline-block;
			vertical-align: center;
		}
	}

	&.is-light {
		min-width: auto;
		padding: 0 0 0 0;
		border-bottom: 0;

		&:before,
		&:after {
			display: none;
		}

		&:hover,
		&:focus {
			background-position: 7px 18px;
		}
	}
}

.work-illu a {
	background-size: calc(100% - 12px) calc(100% - 12px);

	span:before,
	&:after,
	&:before {
		transform: translateY(12px) translateX(12px);
		opacity: 0;
		transition: all .275s;
	}

	&:hover,
	&:focus {
		background-position: $bgPositionIn;
		// outline: none;

		span:before,
		&:after,
		&:before {
			opacity: 1;
			transform: translateY(0) translateX(0);
			transition: all 0;
		}
	}

	&:before,
	&:after {
		bottom: -12px;
		right: -12px;
		border-radius: 0;
	}

	&:before {
		left: 12px;
		height: 14px;
	}

	&:after {
		top: 12px;
		width: 14px;
		bottom: -11px;
	}
}


// IE11 debug
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.work-illu a {
		span:before,
		&:after,
		&:before {
			transition: none!important;
		}
	}

	.work-illu {
		.see-more, a, img, .icon {
			transition: none!important;
		}
	}

	.button {
		transition: none!important;
	}
}

button.virgin-colada {
	padding: 0;
	margin: 0;
	border: 0;
	background: none;
	box-shadow: none;
}

.inlined-buttons {
	margin: 1em -20px .5em;

	.button {
		margin-left: 20px;
		margin-right: 20px;
	}
}



 // gray boxes on homepage and other pages
 .boxed--gray {
 	padding: 24px;
 	border: 1px solid $gray-10;
 	background: $gray-02;
	border-radius: 2px;
 }


 .boxed--light {
 	border-radius: 2px;
 	padding: 24px;
	margin: 0 0 50px 0;
 	border: 1px solid $gray-10;
 	background: #fff;
 	position:relative;

	@media (min-width: $xsmall-width) {
		padding: 32px;
		margin: 0 0 48px 0;
	}

	@media (min-width: $small-width) {
		padding: 40px;
		margin: 0 0 60px 0;
	}

	@media (min-width: $website-width) {

	}

 	&:after {
 		@include stripes();
 		content: '';
 		position: absolute;
 		left: 24px;
 		right: 24px;
 		top: 100%;
 		bottom: -16px;

		@media (min-width: $xsmall-width) {
	 		left: 32px;
	 		right: 32px;
	 		top: 100%;
	 		bottom: -18px;
	 	}

		@media (min-width: $small-width) {
	 		left: 40px;
	 		right: 40px;
	 		top: 100%;
	 		bottom: -20px;
	 	}

 	}
 }





/**
 * Works // confs // posts thumbs
 */



// Section Skills exception for mobile view

.section-skills {

	// default small
	
	.skill-grid {
		flex-direction: column;
		gap: 1em;
	}

	.skill-item {
		border: 1px solid $gray-10;
 		background: #fff;
		border-radius: 2px;
		padding:1.2em;
		row-gap: 0.6em;
	}


	.illustration-expert { 
		place-self: center;
		width: clamp(20px,90%,240px);
	}	

	// max width 679px
	@media (max-width: $xsmall-width - 1px) {
		h3 {
			text-align: center;
		}
	}

	// default 914 + layout
	@media (min-width:$xsmall-width) and (max-width:$xlarge-width){
		.skill-grid{
			padding: 2em;
		}

		.skill-item {
			padding:2.25em;
			column-gap: 2em;
			grid-template-columns: 1fr 1fr;

			h3 {
				align-self: center;
			}

			.illustration-expert { 

				width: clamp(40px,90%,400px);
				grid-row: 1 /span 2;
				grid-row: 1/span 2;
			}	
			
			&:nth-child(odd) {
				
	
				.illustration-expert {
					grid-column: 1 / 2;
				}
				
			}
			&:nth-child(even) {
				// grid-template-columns: auto 50% ;

				.illustration-expert {
					grid-column: 2 / 2;
				}
			}
		}

	}

	// 4 columns grid on bigger screens

	@media (min-width: $xlarge-width +1px){
		.container {
			width: clamp( $xlarge-width +1px, 90%, 1980px);
		}

		.skill-grid {
			flex-direction: row;
			gap: 1.5%;
		}
		.skill-item { 
			flex-basis: 100%;

			h3 {
				align-self: start;
				text-align: center;
			}
		}

	}

}




/**
 * Portfolio items 
 */
.work-item {
	@media (min-width: $small-width) {
		display: flex;
		align-items: center;

	}

	@media (min-width: $website-width) {
		margin: 0 $overflow-neg-margin 80px $overflow-neg-margin;
		// gap: 70px;
	}


	&:nth-child(odd) {
		.work-illu {
			order: 2;
		}
	}

	&:nth-child(even) {
		.work-texts {

			@media (min-width: $small-width) {
				padding-right: 0;
				padding-left: 30px;
			}

			@media (min-width: $small-width) {
				padding-left: 50px;
			}
		}

		.work-desc {
			a {
				border-bottom-color: $secondary-30;

				&:hover,
				&:focus {
					// background: $secondary-30;
				}
			}
		}

		.icon {
			color: $secondary-30;
		}
	}
}
.work-illu, .work-texts{
	flex: 0 1 50%;
}

.work-texts {

	h3 {
		@media (max-width: $small-width - 1px) {
			text-align: center;
		}
	}


	@media (min-width: $small-width) {
		padding-right: 30px;
	}

	@media (min-width: $small-width) {
		padding-right: 70px;
	}
}


@media (max-width: $small-width - 1px) {
	.work-cta {
		text-align: center;
	}
}

.work-illu {

	@media (min-width: $small-width) {
		// width: 46.5%;
		// flex-shrink: 0;
	}

	@media (max-width: $small-width - 1px) {
		margin-bottom: 1.5em;
		text-align: center;
	}

	.see-more {
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		font-size: 1.45rem;
		font-weight: 700;
		line-height: 1.125;
		transform: translateY(-50%) translateY(10px);
		text-align: center;
		padding: 1rem 2rem;
		color: $secondary-60;
		opacity: 0;
		visibility: hidden;
		transition: all .275s;

		@media (min-width: $xsmall-width) {
			font-size: 1.625rem;
		}

		&:before {
			display: none;
		}
	}

	img {
		transition: all .275s;
	}

	a {
		position: relative;
		line-height: 0;

		&:hover,
		&:focus {
			img {
				opacity: 0;
				transform: translateY(14px) translateX(14px)
			}

			.see-more {
				opacity: 1;
				visibility: visible;
				transition: all .275s .275s;
				transform: translateY(-50%) translateY(0);
			}
		}
	}

	.icon {
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateY(52px) translateX(-50%);
		opacity: 0;
		transition: all .275s;
		color: $primary-50;
	}

	a:hover,
	a:focus {
		.icon {
			transform: translateY(26px) translateX(-50%);
			opacity: 1;
			transition: all .275s .125s;
		}
	}
}

.work-desc {
	margin: 1em 0;

	@media (min-width:$xsmall-width) and (max-width: $small-width) {
		max-width: 600px;
		margin: 1em auto;
	}

}
.work-desc.entry-content ul li {
	margin: .4em 0;
}

.work-illu img{
	border: 1px solid $gray-10;
}


.slideshow {
	padding: 2em 0 3em;
	position: relative;
	width: 100%;

	.prev,
	.next {
		position: absolute;
		bottom: 0;
		color: $primary-50;
		transform: translateY(-39%);
		transition: all .275s;

		&:hover {
			transform: translateY(-60%);
			outline: none;
		}

		&:active {
			transform: translateY(-55%);
		}

		@include accessibilityfocus(0.2em);
	}

	.prev {
		left: -10px;
	}
	.next {
		right: -10px;
	}

	@media (min-width: $website-width) {
		.prev,
		.next {
			bottom: auto;
			top: 50%;
			transform: translateY(-60%);

			&:focus,
			&:hover {
				transform: translateY(-70%);
			}

			&:active {
				transform: translateY(-65%);
			}
		}
		.prev {
			left: $overflow-neg-margin;
		}
		.next {
			right: $overflow-neg-margin;
		}
	}

	.slide {
		display: inline-block;
		vertical-align: middle;
		width: 185px;
		height: 140px;
		margin-right: 20px;
		background: #FFF;
		border: 1px solid $gray-10;
	}
}
.slides-container {
	position: relative;
	width: 100%;
	overflow: hidden;
	white-space: nowrap;
}
.slides-list {
	display: inline-block;
}

/**
 * Content styles
 */

.small-width-content {
	max-width: $entry-content-size;
	margin: 0 auto;

	p {
		margin: 1.5em 0;
	}

	ul {
		margin: 1.5em 0;
		padding: 0;
	}
}


/* Adding some padding before the footer */


section.section-trust {
	padding-bottom: 30px;
}

/**
 * Main Footer
 */
.main-footer {
	background: $primary-50;
	padding: 65px $global-lat-spacing 0;
	color: $secondary-60;
	a path {
		fill: $secondary-60;
	}

	.h2-like + p {
		font-size: 1em;
		@media screen {
			
		}
	}
}

// change the contact zone from 1 column to 2
.contact-follow {
	margin-top: 1em;
	flex-direction: column;

	@media (min-width: $small-width ) {
		flex-direction: row;
		gap: 2em;
		flex: 0 1 100%;
	}
}



.follow-my-work {

	// only have the margins on smaller viewports
		@media (max-width: $small-width - 1px) { 
			padding-block: 1em;
	}

		@media (min-width: $small-width) { 
			flex: 1 0 233px;
	}
}


.piou-piou {

	
	margin-top: 1em;

	// only in flexbox for smaller viewports 
	@media (max-width: $small-width - 1px) { 
		.social-section ul {
			display: flex;
			flex-wrap: wrap;
			margin-top: 0.3em;

			li{
				margin-right: 1em;
				margin-top: 0.6em;
			}
		}
	}
	
	
	li {
		@media (min-width: $small-width) {
			margin-top: 0.8em;
		}

	}
	a {
		color: inherit;
		font-weight: 700;
		text-decoration: none;
		transition: all .275s;

		&:hover,
		&:focus{
			background: #FFF;
			outline-offset: 1px;
		}

		@include accessibilityfocus();

		@media (max-width: $xsmall-width) {
			span {
				// @include screenreader-hidden();		
			}
		}
	}
	svg {
		width: 1.5em;
		vertical-align: middle;
		margin-right: 0.4em;
		margin-top: -0.3em;
	}
	@media (min-width: $small-width) {
		
	}
	.social-section:nth-of-type(2){
		margin-top: 1.5em;
	}
}

.error-block {
	position: relative;
	margin: 1em auto;
	padding: 16px 24px 16px 64px;
	font-weight: 700;
	color: #FFF;
	background: $error;
	border-radius: 5px;

	svg {
		position: absolute;
		top: 50%;
		margin-top: -18px;
		left: 16px;
	}

	&:after {
		content: '';
		display: table;
		width: 100%;
		clear: both;
	}
}

/* contact form */
// .disclaimer{
// 	line-height: 1.2;
// 	font-size: .9em;
// 	margin-top: 1em;

// }
.faq {
	line-height: 1.3;
	margin-top: 1em;
	font-size: .9em;
}

.required-fields {
	margin-top: 1.1em;
	padding-top: 4em;
	position: relative;
	background-image:url(../img/plante-contactform.svg);
	background-position: top left;
	background-repeat: no-repeat;
}

[id="contactform"] {
	box-sizing: border-box;

	a {
		color:$secondary-60;
	}
	@extend .boxed--gray;

	padding: 24px;

	@media (min-width: $xsmall-width) {
		padding: 2em;
	}

	.h3-like + .small {
		margin-top: -5px;
	}

	.flex {
		 flex-direction: column;

		@media (min-width:$medium-width){
			gap:2em;
			flex-direction: row;
		}

	}
	@media (max-width:$medium-width - 1px) {
		.second-col{
			margin-top: 1em;
		}
		
	}
	

	label {
		font-family: $ff-booster;
	}

	textarea {
		height: 183px;
		transition: color .275s, border .275s, box-shadow .275s;
		resize: vertical;

		+ .error {
			margin-top: -16px;
		}
	}

	.message-line {
		margin-top: 17px;
	}

	.submit-line {
		margin-top: .75em;
		margin-right: -6px;
		// text-align: right;


		.button {
			margin-bottom: 0;
			width: 220px;
			padding: 10px;
			max-width: 100%;
			border-radius: 3px;
		}
	}

	.error {
		position: relative;
		float: right;
		padding: 0 10px;
		margin: -11px 5px 0;
		font-size: .875rem;
		font-weight: 700;
		color: $error;
		background: #FFF;
		background: linear-gradient(to bottom, #FFF 49%, $gray-02 49%);
	}

	[aria-invalid="true"] {
		border-color: $error;
	}
}

.thanks {
	padding-top: 55px;
	text-align: center;

	@media (min-width: $website-width) {
		margin-right: $overflow-neg-margin;
	}

	.h2-like {
		font-size: 2rem;
		color: $gray-70;
	}

	.big {
		color: $gray-70;
		margin-bottom: 16px;
	}
}

.thanks-detail {
	padding: 20px 24px;
	margin: 16px 30px 30px;
	border-top: 1px solid $gray-10;
	border-bottom: 1px solid $gray-10;
}

.footer-with-unicorns {
	padding: 14px $global-lat-spacing;
	background: $secondary-60;

	p {
		color: #FFF;
		font-size: .75rem;
	}

	a {
		border-bottom: 0;
		text-decoration: underline;
		color: #fff;
		display: inline-block;
		padding: 0 .3em;

		&:hover,
		&:focus {
			color: $secondary-60;
			background-color: $primary-50;
		}

		@include accessibilityfocus(0.12em, white)
	}
}

/* Section conf */
.section-conf {
	margin-bottom: 4em;
	.divider {
		margin-bottom: 2.5em;
	}
	.h2-like + div {
		margin-top: 2.5em;
	}
	.entry-content {
		margin-top: 2em;
	}
}

.main-section{
	margin-bottom: 4em;
}

/**
 * Blog Specifics
 */

@import 'blog';


.m1 {
	margin: 1em 0;
}
.mt1 {
	margin-top: 1em;
}

.mt40 {
	margin-top: 40px;
}


/**
 * Gutenberg class utilities
 */

 .has-yellow-background-color {
	 background: $primary-50;
 }

 .has-lightest-gray-background-color{
	background: $gray-02;
 }


/**
* Portfolio CSS
**/

 /* See warning below */

.fullscreen {
	position: relative;
	left: 50%;
	right: 50%;
	width: 100vw;
	margin: 0 -50vw;

	> * {
		width: $entry-content-size;
		max-width: 100%;
		margin: 1em auto 0;
		padding-top: 15px;
	}
}



/** Plant SVG styles **/


// SVG colors code
.primary-filled {
  fill: $primary-50;
}
.primary-border {
  fill: none;
  stroke: $primary-50;
  stroke-width: 2.44;
  stroke-linecap: round;
}

.stem {
  stroke-width: 2.44px;
  fill: none;
  stroke: $secondary-60;
  stroke-linecap: round;
}

.secondary-border {
  fill: $secondary-60;
}

.branch,
.heart-border {
  fill: none;
  stroke-linecap: round;
}

.branch {
  stroke: $secondary-60;
  stroke-width: 2.44px;
}

.pot-bg {
  fill: $secondary-30;
}

.heart-bg {
  fill: #fff;
}

.pot-border {
  fill: $secondary-50;
}

.heart-border {
  stroke: $secondary-50;
  stroke-linejoin: round;
  stroke-width: 2.9px;
}

#trigger {
  position: fixed;
  right: 24px;
  bottom: 24px;
}




/**
 * Newsletter
 */


.send-the-content-bloc {
	 max-width: $small-width;
	 margin: 0 auto 4em;
	 position: relative;

	 h3 {
		 padding-right: 2.5em;
	 }

	.nldescription {
		margin-top: 1em;

		@media (min-width: $small-width) {
			margin-right:200px;
		}
	}
	
	

	// .send-that-content-box{
	// 	display: flex;
	// 	flex-direction: row;
	// 	flex-wrap: wrap;
	// 	justify-content: space-between;

	// 	.nl-email, .nl-name {
	// 		margin-right: 1em;
	// 		// margin-top: 1em;
	// 		/* @TODO use the gap once safari support is better */
	// 	}
	// 	.nl-button {
	// 		margin-top: 1em
	// 		/* @TODO use the gap once safari support is better */
			
	// 	}

	// 	button, input, a {
	// 		@include accessibilityfocus(0.12em, white)
	// 	}
	// 	button {
	// 		border-radius: 3px;
	// 	}

	// 	.nl-email {
	// 		flex-shrink: 1;
	// 		flex-grow: 2;
	// 		flex-basis: 250px;
	// 	}

	// 	.nl-name {
	// 		flex-shrink: 1;
	// 		flex-grow: 2;
	// 		flex-basis: 180px;
	// 	}


	// 	.nl-button {
	// 		display: flex;
	// 		flex-flow: row;
	// 		flex-shrink: 1;
	// 		flex-grow: 0;
	// 		align-items: flex-end;
	// 		text-align: right;

	// 	}

	// 	.button {
	// 		padding: 0.67em 1.2em;
	// 		margin: 2px;

	// 		@media (min-width: $small-width){
	// 			padding: 0.55em 1.2em;
	// 		}
	// 	}
	// }

	.decoration-illu {
		display: none;
		svg {
			position:absolute;
			// width: clamp(60px, 18%, 200px);
			// right: clamp(5px, 2%, 10px);
			top: 0;
		}

		@media (min-width: $xxsmall-width) {
			
			// svg {
			// 	top: 2em;
			// }
		}

		@media (min-width: $small-width) {
			display: block;
			svg {
				width: 150px;
				right: 0;
				top: 0;
			}
		}

	}
}


/** clean newsletter if in a section **/
.section-newsletter .send-the-content-bloc{
	margin: 1em auto;
	border:none;
	max-width: $container-width;

	&.boxed--light::after{
		background:none;
	}
	
}


.blog .blog-homepage .send-the-content-bloc {
	max-width: $container-width ;
	padding-bottom: 2em;
	margin-top: 2em;

	
	.decoration-illu {
		@media (min-width:$medium-width) {
			svg {
				right:-4px;
			}
		}
	}
}
 
// email octopus
.send-that-content-box {
	[data-form="fb82bfda-936d-11ee-9472-1baa2f8d53d2"].inline-container,
	[data-form="b5ec3644-937d-11ee-a65b-2b47618db447"].inline-container{
		width: 100% !important;
		max-width: 100% !important;
		
		.form-container{
			width: 100% !important;
			max-width: 100% !important;	
		}

		.btn-primary {
			cursor: pointer;
			font-size: 1em;
			font-weight: 700;
			letter-spacing: -.005em;
			border:none;
			font-family: $ff-booster;
			padding: .5em;
			transition: background 150ms ease-in-out;
	
			&:hover{
				background: $primary-50;
			}
		}
		.form-control:focus,
		.btn-primary:focus{
			box-shadow: none;
		}
		.btn-primary, input, a {
			@include accessibilityfocus(0.12em, white)
		}
		.btn-primary {
			border-radius: 3px;
		}
	
		.main-form {
			gap:.3em;
		}
		.emailoctopus-form [eo-form-fields-container] {
			gap:.3em;
		}
	
	
		.mastfoot {
			transform: scale(0.7) !important;
			margin: 0 !important;
			a, p {
				font-family: $ff-common;
				font-weight: 300;
				color: #FFF !important;
			}
		}
	}

} 

// EN only
.send-that-content-box [data-form="b5ec3644-937d-11ee-a65b-2b47618db447"].inline-container{
	@media (min-width: $entry-content-size) {
		.main-form {
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-between;
			
		}
		.main-form .emailoctopus-form-row {
			flex-shrink: 1;
			flex-grow: 2;
			flex-basis: 250px;
			
		}
		.main-form input {
			flex-shrink: 1;
			flex-grow: 1;
			flex-basis: 50px;
		}
	}
	
}




// .mailchimp.freebiesform, 
// .resource-newsletter-form{
// 	.field-group {
// 		margin-bottom: 1em;
// 	}
// }

// .resource-newsletter-form {
// 	margin-top: 2em;
// 	padding-bottom: 1em;
// }


@media (max-width: 679px) {
	.author-identity,
	.post-metadata {
		flex-shrink: 1;
		flex-grow: 1;
		flex-basis: 50%;
	}
}
@media (max-width: 540px) {
	.author-identity,
	.post-metadata {
		flex-basis: 100%;
	}

	.author-block .post-metadata {
		text-align: left;
	}
}

